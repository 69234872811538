<template>
  <div class="details-page">
    <div class="bd">
      <article>
        <div class="page-title">{{ pageData.title }}</div>
        <div class="page-time" v-html="pageData.title1"></div>
        <div class="content" v-html="pageData.content"></div>
      </article>
    </div>
  </div>
</template>
<script>
import detailsList from './utils/details1.json';

export default {
  name: 'ThreejsDetails',
  data() {
    return {
      pageData: {},
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
  },
  created() {
    const { id } = this;
    this.pageData = detailsList[id];
    console.log(this.pageData);
  },
};
</script>
<style lang="scss" scoped>
  .details-page {
    width: 100%;
    min-height: 100%;
    background-color: #fff;
    position: relative;
  }
  .bd {
    padding: 0 1rem;
    font-family: arial,Microsoft YaHei,Helvetica,sans-serif;
    .page-title {
      color: #212121;
      font-size: 34px;
      padding-top: 50px;
      padding-bottom: 50px;
      font-weight: bold;
    }
    .page-time {
      font-size: 22px;
      color: #999;
      font-weight: 500;
      /deep/ span {
        padding-right: .625rem;
      }
    }
    .content {
      margin-top: .8rem;
      margin-bottom: 1.8rem;
      line-height: 50px;
      // padding: 0 0.8rem .8rem;
      /deep/ img{
        max-width: 100%;
        height: auto;
      }
    }
  }
</style>
